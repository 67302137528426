// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);

.enter-options{
  display: block !important;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 99;
}
hr {
  border-top: 1px solid #e0e0e0;
}

.form-text{
  margin-left: 17px;
}

.sc-ion-searchbar-md {
  border-radius: 50px !important;
  box-shadow: none !important;
  border: 1px solid #f0f0f0;
  .searchbar-search-icon {
    border: none !important;
  }
  --background: #f0f0f0;
}

.item-content {
  background-color: #ffffff;
}

.searchbar-input {
  font-size: 14px !important;
}

ion-title{
  padding: 0;
}

.middle {
  float: left;
  //background: rgb(124, 126, 124);
  width: 50%;
 
}

.right {
  float: right;
  //background: blue;
 // width: 25%;
 
}
.text-center{
  display: flex;
  width: 100%;
  height: 18px;
  justify-content: center;
  align-items: center;
  
}


/* ion-menu {
  --ion-background-color: var(--ion-color-primary-dark);
  --ion-text-color: var(--ion-color-primary-contrast);

  ion-toolbar {
    --background: var(--ion-color-primary);
  }

  ion-list {
    background: transparent;
  }
} */

/* 
ion-searchbar{
  padding: 0;
  margin: 0;


  .searchbar-input-container{
  .searchbar-input{
  border-radius: 0px  !important;
  transform: scale(1.1) !important;
  background-color: crimson;
  }
  }
  } */
.searchbar {
  padding: 0;
  margin: 0;

  .searchbar-input-container {
    border-radius: 0px !important;
    border: 1px solid transparent;

    .searchbar-input {
      border-radius: 0px !important;
      transform: scale(1.1) !important;
      background-color: transparent !important ;
    }
  }
}

.searchh {
  padding: 0;
  margin: 0;

  .searchbar-input-container {
    .searchbar-input {
      border-radius: 0px !important;
      transform: scale(1.1) !important;
      //background-color: green !important ;
      width: 80vw
    }
  }
}

.searchitem,
.searchCategory {
  padding: 0;
  //margin: 2px;
  .searchbar-input-container {
    .searchbar-input {
      //height: 53px;
      margin-left: 0; ;
      border-radius: 0px !important;
      padding-right: 0;
      transform: scale(1.1) translateX(-0px)!important;
    }
    .searchbar-search-icon {
      left: 7px;
      top: 11px;
      width: 21px;
      height: 21px;
      transform: scale(-1.2, 1.2);
    }
  }
}

.item-product {
  position: absolute;

  margin: 0;
  padding: 0;
  .item-native {
    .item-inner {
      padding-inline-end: 0;
      background-color: blue;
    }
  }
}

.product-item,
.sell-item {
  --inner-padding-end: 0px;
  /* --padding-start:0px */
}

.product-grid,
.sell-grid , .search-grid  {
  height: 100%;
}
.product-row,
.sell-row, .search-row   {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}


.searchItemm{
  padding: 0;
  //margin: 2px;


  .searchbar-input-container {
    .searchbar-input {
      //height: 53px;
      margin-left: 0; ;
      border-radius: 0px !important;
      padding-right: 0;
     // transform: scale(1) translateX(-16px)!important;
      //background-color: green !important ;
      width: 62vw
    }
    /* .searchbar-search-icon {
      left: 7px;
      top: 11px;
      width: 21px;
      height: 21px;
      display: none;
      transform: scale(-1.2, 1.2);
    } */
  }
}

.searchItemmCodBar{
  padding: 0;
  //margin: 2px;


  .searchbar-input-container {
    .searchbar-input {
      //height: 53px;
      margin-left: -30px; ;
      border-radius: 0px !important;
      padding-right: 0;
     // transform: scale(1) translateX(-16px)!important;
      //background-color: green !important ;
      width: 70vw
    }
    .searchbar-search-icon {
      left: 7px;
      top: 11px;
      width: 21px;
      height: 21px;
      display:none; width:0; height:0;
      transform: scale(-1.2, 1.2);
    }
  }
}

.searchItemm-sellmodal{
  padding: 0;
  //margin: 2px;


  .searchbar-input-container {
    .searchbar-input {
      //height: 53px;
      margin-left: 0; ;
      border-radius: 0px !important;
      padding-right: 0;
     // transform: scale(1) translateX(-16px)!important;
      //background-color: green !important ;
      width: 47vw
    }
    /* .searchbar-search-icon {
      left: 7px;
      top: 11px;
      width: 21px;
      height: 21px;
      
      transform: scale(-1.2, 1.2);
    } */
  }
}




.searchItemm-capital{
  padding: 0;
  //margin: 2px;


  .searchbar-input-container {
    .searchbar-input {
      //height: 53px;
      margin-left: 0; ;
      border-radius: 0px !important;
      padding-right: 0;
     // transform: scale(1) translateX(-16px)!important;
      //background-color: green !important ;
      //*width: 47vw
    }
    /* .searchbar-search-icon {
      left: 7px;
      top: 11px;
      width: 21px;
      height: 21px;
      
      transform: scale(-1.2, 1.2);
    } */
  }
}




ion-spinner {
  width: 20px !important;
  height: 20px !important;
  margin-left: 7px !important;
}

.reestablishPass{
 background: rgb(75, 146, 255) !important;
 //background: royalblue !important;
  color: white !important;
}


.validation-errors {
  padding-top: 5px;
  text-align: left;

  .error-message {
    color: var(--ion-color-danger);
    font-size: 12px;
  }
}

.text-img {
  line-height: 1;
  font-size: 0.26em;
  text-transform: capitalize;
}


//imagen de :ayuda al crear payMEthods
.pay-method-allert-class{
  --max-height:485px;

  .alert-message.sc-ion-alert-md{
    max-height: 323px;
    padding: 5px;
  }

  img{
    height: 330px;
    width: fit-content;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

  }

}


ion-toolbar{
  min-height:55px;
}

.circle-button{
  min-height: 50px;
}



 ion-item {
  --border-width: 2px;
  --border-color: rgb(233, 233, 233);
  --border-radius: 8px;
}

.transparent-modal .modal-wrapper {
  --background: rgba(44, 39, 45, 0.7);
}

.scan-box{
  border: 2px solid #fff;
  box-shadow: 0 0 0 100vmax rgb(0 0 0 / 65%);
  display: block;
  content: '';
  left: 50%;
  height: 40%;
  position: absolute;
  top: 50%;
  transform: translate(-50% , -50%);
  width: 96%;
}

.pay-method-allert-class{
  margin: auto;
}

.menu-content-open{
  pointer-events: all !important;
}




//-----------------------------stteper-----------------//

.flex {
  display: flex;
}

.full-width {
  width: 100% !important;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.text-tiny {
  font-size: 11px !important;
}

.text-small {
  font-size: 12px !important;
}

.text-medium {
  font-size: 14px !important;
}

.text-normal {
  font-size: 16px !important;
}

.text-large {
  font-size: 24px !important;
}

.text-xlarge {
  font-size: 36px !important;
}

.text-xxlarge {
  font-size: 48px !important;
}

.radius {
  border-radius: 8px !important;
}

.divider {
  background: var(--ion-color-light);
  margin: 8px 0;
  height: 1px;
  width: 100%;
}

.bold {
  font-weight: bold;
}


ion-col>div{
  text-align: center;
}

.help-allert-class{
  display: flex;
  justify-content: center;
  align-items: center;

}

.error-message {
  color: var(--ion-color-danger); 
  margin-left: 8px;
}

.horizontal-img{
  width: 60%;
}

.submit-button{
  width: 95%;
  height: 50px;
  margin: auto;
  /*  
   margin: auto;
   position: relative;
   bottom: 4px; */
   position: absolute;
   bottom: 8px; 
   left: 2.5%;
   
}

.next{
  height: 50px;
}






// linear-gradient(90deg, rgba(3,119,62,1) 0%, rgba(3,119,62,1) 6%, rgba(90,160,58,1) 81%)


.toolbar-background {
  border: none;
}


.send-icon{
  color: var(--ion-color-primary);
}


.customAlert{


  .alert-button.sc-ion-alert-md:nth-child(1) {
  padding-left: unset;
  padding-right: unset;
  -webkit-padding-start: 10px;
  padding-inline-start: 10px;
  -webkit-padding-end: 10px;
  padding-inline-end: 10px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  background: -webkit-linear-gradient(bottom, rgb(112, 58, 255 ), rgb(112, 68, 255));
 
  border-radius: 3px;
 // border: solid 3px white;
  color: white;

  width: 100%;
  //text-align: center;
    
}


  .alert-button.sc-ion-alert-md:nth-child(2) {

  padding-left: unset;
  padding-right: unset;
  -webkit-padding-start: 10px;
  padding-inline-start: 10px;
  -webkit-padding-end: 10px;
  padding-inline-end: 10px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  background: -webkit-linear-gradient(bottom, rgb(31, 107, 196), rgb(13, 58, 110));

  border-radius: 3px;
 // border: solid 3px white;
  color: white;
  width: 100%;
  margin-bottom: 7px;


}

.alert-button.sc-ion-alert-md span{
  justify-content:center !important

}





}
